import React from "react"

import { getVariables } from "../lib/utils"
import { useStaticQuery, graphql, Link } from "gatsby"
import ItemPost from "./ItemPost"


const ListReviews = ({ container = false, className = null, linkAll = false }) => {

    const reviews = useStaticQuery(graphql`
    query {
        allDatoCmsReview(
            filter: {location: {websiteUrl: {eq: "za.mattress.zone"}}, locale: {eq: "en"}}
        ) {
            nodes {
                id
                title
                slug
                featuredImage {
                    gatsbyImageData(imgixParams: {w: "400", h: "250", fit: "crop", colors: 10})
                    alt
                }
                meta {
                    updatedAt
                }
            }
        }
    }
    `)

    const variables = getVariables()

    let htmlAll = ''
    if (linkAll) {
        htmlAll = <div className="text-center mb-4">
            <Link to={`/${variables.reviews}`} className="mt-4 inline-block py-3 px-6 bg-zone-14 text-white duration-300 uppercase font-grotesk rounded-full hover:bg-zone-9">{variables.view_all_reviews}</Link>
        </div>
    }

    return (
        <div className={className}>
            <div className={` ${container ? 'container mx-auto' : null} `}>
                <h1 className="mb-4 text-4xl font-playfair font-bold text-zone-1">{variables.recent_reviews}</h1>
                <hr className="mb-4" />

                <div className="grid gap-6 grid-cols-1 md:gap-8 sm:grid-cols-2 lg:grid-cols-3">
                    {reviews.allDatoCmsReview.nodes.map((review) => {
                        return (
                            <ItemPost
                                link={`/${variables.review}/${review.slug}`}
                                image={review.featuredImage}
                                title={review.title}
                                date={review.meta.updatedAt}
                                btnLabel={variables.view_article} 
                                key={`key-list-review-${review.id}`} />
                        )
                    }
                    )}
                </div>

                {htmlAll}
            </div>
        </div>
    )
}

export default ListReviews
